const duration = 3000
const activeClass = "opacity-100"
let timers = {}

export const init = () => {
    if ("IntersectionObserver" in window) {
        let observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const wrapper = entry.target
                const id = wrapper.dataset.id

                // This targets the wrapper div on the image.
                // We might need to make this more specific in the future.
                const images = wrapper.querySelectorAll("div")

                for (const image of images) {
                    image.style.transitionDuration = `${duration}ms`
                }

                if (entry.isIntersecting) {
                    setTimeout(() => {
                        const timer = setInterval(() => {
                            fade(images, true)
                        }, duration)

                        timers[id] = timer
                    }, wrapper.dataset.delay)
                } else {
                    clearInterval(timers[id])
                    delete timers[id]
                }
            })
        }, {})

        document.querySelectorAll(".js-cross-fade").forEach(wrapper => {
            observer.observe(wrapper)
        })
    }
}

const fade = images => {
    for (const image of images) {
        if (image.classList.contains(activeClass)) {
            image.classList.remove(activeClass)

            if (image.nextElementSibling) {
                image.nextElementSibling.classList.add(activeClass)
            } else {
                images[0].classList.add(activeClass)
            }

            break
        }
    }
}
