import * as crossFade from "./cross-fade"
import * as stats from "./stats"
import "../css/main.css"

document.addEventListener("DOMContentLoaded", function() {
    document.documentElement.classList.remove("no-js")

    // Component initializations
    crossFade.init()
    stats.init()
})
