import { CountUp } from "countup.js"

export const init = () => {
    if ("IntersectionObserver" in window) {
        let observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    countUp(entry.target)
                }
            })
        }, {})

        document.querySelectorAll(".js-stats").forEach(wrapper => {
            observer.observe(wrapper)
        })
    }
}

const countUp = stat => {
    const { number, prefix, suffix } = stat.dataset
    const countUp = new CountUp(stat, number, {
        prefix: prefix,
        suffix: suffix,
    })

    countUp.start()
}
